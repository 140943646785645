body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.about {
    color: #212121;
}

.whiteproduct_button {
    box-shadow: 0 5px 15px rgba(0,0,0,.35);
}